import { isArray } from '../util';
import BaseMixin from './BaseMixin';
import Action from './Action';
import Scope from "./Scope";
import Permission from "./Permission";

export default class Resource extends BaseMixin() {

	constructor(props = {}){

		super(props);

		let {

			resource = null,
			scopes = [],
			actions = [],
			master = false

		} = props;

		this.resource = resource;
		this.scopes = [];
		this.actions = [];
		this.master = master;

		for(let x in actions){

			let a = !(actions[x] instanceof Action) ? new Action(actions[x]) : actions[x];
			a.resource = this;
			this.actions[x] = a;
		}

		for(let s of scopes){

			if(!(s instanceof Scope)) throw new Error(`Invalid scope passed to Resource constructor.`);
		}

		this.scopes = scopes;
	}

	hasAction(action, active = false){

		return this.actions.some(a => ((a.key === action || a.id === action) && (active ? a.active : true)));
	}

	getAction(action, active = false){

		return this.actions.find(a => ((a.key === action || a.id === action) && (active ? a.active : true)));
	}

	belongsToScope(scope){

		return this.scopes.some(s => (s.key === scope || s.id === scope));
	}

	getScopesKeys(){

		return this.scopes.map(s => s.key);
	}

	getActionsData(actions){

		let data = {};

		for(let a of actions){

			let action = this.getAction(a, true);
			if(!action) throw new Error(`The action '${a}' not exists in resource '${this.key}'.`);

			data[action.key||action.id] = {

				data: action.data,
				value: action.value
			}
		}

		return data;
	}

	isMaster(){

		return this.master;
	}

	isAllowed(action){

		let act = this.getAction(action);
		return act ? act.active : this.master;
	}

	isAllowedMany(actions, match = Permission.MATCH_ALL){

		if(!isArray(actions)) throw new Error(`Invalid actions array arg.`);
		// if(this.master) return true;

		for(let action of actions){

			let act = this.getAction(action);
			let result = act ? act.active : this.master;

			switch(match){

				case Permission.MATCH_ALL: {

					if(!result) return false;
					break;
				}

				case Permission.MATCH_ONE: {

					if(result) return true;
					break;
				}

				case Permission.MATCH_NONE: {

					if(result) return false;
					break;
				}
			}
		}

		return match !== Permission.MATCH_ONE;
	}
}
