import BaseMixin from './BaseMixin';

export default class Action extends BaseMixin() {

	constructor(props) {

		super(props);

		({

			resource: this.resource = null,
			data: this.data = null,
			value: this.value = null

		} = props);

		this._ensure(['id', 'key']);
	}
}