import maskit from "vue-the-mask/src/maskit";
import EventMixin from "@/mixins/EventMixin";
import moment from "moment";
import { message } from "ant-design-vue";

export default {

    bind: function(el, binding, vnode) {

        let format = vnode.componentOptions.propsData.format;
        if(!format) return;

        const onEvent = EventMixin.methods.$onEvent.bind(vnode.componentInstance);
        let tag = vnode.componentOptions.tag;
        let refs = vnode.componentInstance.$refs;

        // let x = refs.picker.handleOpenChange;
        // refs.picker.handleOpenChange = function() {
        //
        //     console.log(arguments);
        //     return x(...arguments);
        // };

        // refs.picker.$watch(() => {
        //     return refs.picker.$children[0].sOpen;
        // }, (n, o) => {
        //     console.log('sOpen', n, o, refs.picker.$children[0]);
        // }, {immediate: false});

        const tokens = {
            M: { pattern: /\d/ },
            D: { pattern: /\d/ },
            Y: { pattern: /\d/ },
            H: { pattern: /\d/ },
            m: { pattern: /\d/ },
            s: { pattern: /\d/ }
        };

        let cb = e => {

            let input = e.target;
            onEvent(input, 'input', e => {

                let value = maskit(input.value, format, true, tokens);
                input.value = value;
                if(value.length === format.length && !moment(value, format).isValid()) {
                    message.error(tag === 'a-time-picker' ? 'Hora inválida!' : 'Data inválida!', 3);
                    input.value = "";
                }
            });
        };

        switch (tag) {

            case 'a-time-picker':
                let cbt = e => {
                    setTimeout(() => {

                        if(refs.timePicker.$refs.panel) {
                            let input = refs.timePicker.$refs.panel.$children[0].$refs.input;
                            input.focus();
                            cb({target: input});
                        }
                    }, 300);
                };
                onEvent(refs.timePicker.$refs.picker, 'focus', cbt);
                onEvent(refs.timePicker.$refs.picker.nextSibling, 'click', cbt);
                break;
            case 'a-range-picker':
                let cbr = e => {
                    setTimeout(() => {
                        if(refs.picker.$refs.calendarInstance) {
                            let input1 = refs.picker.$refs.calendarInstance.$refs.rootInstance.children[0].children[0].children[0].children[0].children[0].children[0];
                            let input2 = refs.picker.$refs.calendarInstance.$refs.rootInstance.children[0].children[0].children[2].children[0].children[0].children[0];
                            input1.focus();
                            cb({target: input1});
                            cb({target: input2});
                        }
                    }, 300);
                };
                onEvent(refs.picker.$refs.picker.children[0].children[0], 'focus', cbr);
                onEvent(refs.picker.$refs.picker.children[0].children[2], 'focus', cbr);
                onEvent(refs.picker.$refs.picker.children[0].children[3], 'click', cbr);
                break;
            case 'a-date-picker':
            default:
                let cbd = e => {
                    setTimeout(() => {

                        if(refs.picker.$refs.calendarInstance) {
                            let input = refs.picker.$refs.calendarInstance.focusElement;
                            input.focus();
                            cb({target: input});
                        }
                    }, 300);
                };
                onEvent(refs.picker.$refs.input, 'focus', cbd);
                onEvent(refs.picker.$refs.input.nextSibling, 'click', cbd);
                break;
        }
    },
    unbind: function (el, binding, vnode) {

        EventMixin.methods.$offEvent.bind(vnode.componentInstance)();
    }
}
