import {isString, isArray} from "@/resources/util";

const store = new Map;

function getVmEventBagOfTarget(vm, target) {

	let ref = getVmEventBag(vm);
	if(!target) return ref;

	if(!ref.has(target)){

		ref.set(target, ref = []);
		return ref;
	}

	return ref.get(target);
}

function getVmEventBag(vm){

	let ref = store.get(vm._uid);
	if(!ref) store.set(vm._uid, ref = new Map);
	return ref;
}

export default {

	methods: {

		$onEvent(target, event, fn, useCapture = false) {

			// if(!(target instanceof Element || target instanceof Window)) throw new Error(`Target must be a DOMElement to add events.`);
			if(isString(event)) event = event.split(',');
			else if(!isArray(event)) throw new Error(`Events must be a string or array of strings.`);
			if(!event.length) return;

			let bag = getVmEventBagOfTarget(this, target);

			for(let name of event){

				target.addEventListener(name, fn, useCapture);
			}

			bag.push([fn, event]);
		},
		$offEvent(target, event = null, fn = null){

			if(event) {

				if (isString(event)) event = event.split(',');
				else if (!isArray(event)) throw new Error(`Events must be a string or array of strings.`);
			}

			let entries = getVmEventBag(this);

			for(let [_target, _list] of entries){

				if(target && target !== _target) continue;

				for(let y = _list.length-1; y >= 0; y--){

					let [_fn, _events] = _list[y];
					if(fn && fn !== _fn) continue;

					for(let i = _events.length-1; i >= 0 ; i--){

						let _event = _events[i];
						if(event && event.indexOf(_event) === -1) continue;
						_target.removeEventListener(_event, _fn);
						_events.splice(i, 1);
					}

					if(!_events.length) _list.splice(y, 1);
				}

				if(!_list.length) entries.delete(_target);
			}

			// console.log(getVmEventBag(this), getVmEventBag(this) === entries);
		}
	},
	beforeDestroy(){

		this.$offEvent();
	}
}
