import Vue from 'vue';
import Router from 'vue-router';
import hooks from './hooks';

const push = Router.prototype.push;
const replace = Router.prototype.replace;

Router.prototype.push = function(location, onResolve, onReject) {
	if (onResolve || onReject) return push.call(this, location, onResolve, onReject);
	return push.call(this, location).catch(e => {
		if (e && e.name !== "NavigationDuplicated" && !e.message.includes('Avoided redundant navigation to current location')) {
			throw e;
		}
	});
};

Router.prototype.replace = function(location, onResolve, onReject){
	if (onResolve || onReject) return replace.call(this, location, onResolve, onReject);
	return replace.call(this, location).catch(e => {
		if (e && e.name !== "NavigationDuplicated" && !e.message.includes('Avoided redundant navigation to current location')) {
			throw e;
		}
	});
};

Vue.use(Router);

import createNavigation from './navigation';

const createRouter = function(){

	const navigation = createNavigation();

	return new Router({

		mode: 'history',
		base: process.env.BASE_URL,
		routes: navigation.routes,
		menu: navigation.menu,
		watchers: []
	});
};

const router = createRouter();

hooks(router);

router.options.redefineRouter = function(options = {}){

	let {redirect = 'main', query, params} = options;

	const tmp = createRouter();
	router.matcher = tmp.matcher;
	router.options.menu = tmp.options.menu;

	for(let fn of router.options.watchers){

		fn();
	}

	let route = {name: redirect};
	if(query) route.query = query;
	if(params) route.params = params;

	if(redirect) router.push(route);
};

export default router;
