import axios from 'axios';
import store from "../store";
import { message } from "ant-design-vue";

const http = axios.create({

	baseURL: env.API.ADDRESS,
	timeout: env.API.TIMEOUT,
	paramsSerializer(params){
		const serialize = function(obj, prefix) {
			let str = [],
				p;
			for (p in obj) {
				if (obj.hasOwnProperty(p)) {
					let k = prefix ? prefix + "[" + p + "]" : p,
						v = obj[p];
					str.push((v !== null && typeof v === "object") ?
						serialize(v, k) :
						encodeURIComponent(k) + "=" + encodeURIComponent(v));
				}
			}
			return str.join("&");
		};

		return serialize(params);
	}
});

http.setDefaultAuthorization = function(jwt) {

	http.defaults.headers.common['Authorization'] = jwt ? `Bearer ${jwt}` : null;
};

http.setDefaultTimezone = function(timezone) {

	if(timezone)
		http.defaults.headers.common['Time-Zone'] = timezone;
};

http.CancelToken = axios.CancelToken;
http.isCancel = axios.isCancel;

http.createToken = function(){

	return http.CancelToken.source();
};

let requestId = 0;

http.interceptors.request.use((request) => {

	request.id = ++requestId;

	const {vm, cancelOnLeave = null} = request;

	if(vm && cancelOnLeave !== false) {

		// console.log(request);

		const token = http.createToken();
		const cancel = token.cancel;

		request.cancelToken = token.token;
		request._uid = vm._uid;

		store.commit(

			'http/addActiveRequest',
			{

				id: request.id,
				cancel,
				_uid: vm._uid
			}
		);

		// request.vm = null;
	}

	return request;
});

http.interceptors.response.use((response) => {

	store.commit('http/doneActiveRequest', {id: response.config.id, _uid: response.config._uid});
	return response.data || response;

}, (e) => {

	store.commit('http/activeCount', false);
	store.commit('http/failCount', {isCancel: http.isCancel(e)});

	if(
		e.response.status === 401 &&
		e.response.config.url !== '/painel/sessions.login' &&
		e.response.config.url !== '/painel/sessions.logout'
	){

		message.error('Login expirado! Entre novamente para continuar', 5);
		store.dispatch('session/logout');
	}

    throw e.response ? (e.response.data || e.response) : e;
});

http.install = function(Vue, options = {}){

	// Vue.prototype.$http = http;

	store.registerModule('http', {

		namespaced: true,
		state(){

			return ({

				tokens: {},
				active: {

					count: 0,
					// map: unobservable({})
					map: {}
				},
				ok: {

					count: 0
				},
				fail: {

					count: 0,
					cancelCount: 0
				}
			})
		},
		getters: {

			getActiveRequests(state){

				return state.active;
			},
			hasActiveRequest(state){

				return state.activeCount > 0;
			},
			hasVmActiveRequests(state){

				return (_uids) => {

					for(let _uid in state.active.map){

						if(_uids.indexOf(parseInt(_uid)) > -1){

							return true;
						}
					}
				}
			}
		},
		mutations: {

			addToken(state, {$id, token}) {

				if (!state.tokens.hasOwnProperty($id)) {

					state.tokens[$id] = [];
				}

				state.tokens[$id].push(token);
			},
			removeTokens(state, {$id}) {

				delete state.tokens[$id];
			},
			addActiveRequest(state, {_uid, id, cancel}){

				if(cancel) {

					const map = state.active.map;
					let dt = map[_uid];
					if (!dt) dt = map[_uid] = {};

					dt[id] = cancel;
				}

				this.commit('http/activeCount', true);
			},
			doneActiveRequest(state, {_uid, id}){

				let dt = state.active.map[_uid];
				if(!dt) return;

				delete dt[id];

				if(!Object.keys(dt).length) delete state.active.map[_uid];

				this.commit('http/activeCount', false);
				this.commit('http/okCount');
			},
			cancelVmActiveRequests(state, _uids){

				for(let _uid in state.active.map){

					if(_uids.indexOf(parseInt(_uid)) > -1){

						for(let requestId in state.active.map[_uid]){

							state.active.map[_uid][requestId](`Request #${requestId} cancelled.`);
						}

						delete state.active.map[_uid];
					}
				}

			},
			activeCount(state, operation){

				operation ? state.active.count++ : state.active.count--;
			},
			okCount(state, request){

				state.ok.count++;

				if(request){

					state.ok.requests.push(request);
				}
			},
			failCount(state, {isCancel = false}){

				state.fail.count++;

				if(isCancel){


					state.fail.cancelCount++;
				}
			}
		},
		actions: {

			cancelTokens({commit, state}, data) {

				let $c = null;

				if (data.constructor.name === "VueComponent") {

					$c = data;

				} else {

					$c = data.$component;
				}

				if (!$c) throw new Error("Error to retrieve component context to cancel http active requests for component with _uid = " + $c._uid + ".");

				for (let token of state.tokens[$c._uid]) {

					token.cancel("Request aborted.");
				}

				commit("removeTokens", $c);
			}
		}
	});

	const wrap = (vm, method, url, config, data = undefined) => {

		const request = Object.assign({

			method,
			url,
			data,
			vm

		}, config || {});

		return http.request(request);
	};

	Object.defineProperty(Vue.prototype, '$http', {

		configurable: false,
		get(){

			const vm = this._isVue ? this : null;

			return {

				request(config){

					return wrap(vm, undefined, undefined, config);
				},
				get(url, config){

					return wrap(vm, 'get', url, config);
				},
				delete(url, config){

					return wrap(vm, 'delete', url, config);
				},
				head(url, config){

					return wrap(vm, 'head', url, config);
				},
				options(url, config){

					return wrap(vm, 'options', url, config);
				},
				post(url, data, config){

					return wrap(vm, 'post', url, config, data);
				},
				put(url, data, config){

					return wrap(vm, 'put', url, config, data);
				},
				patch(url, data, config){

					return wrap(vm, 'patch', url, config, data);
				}
			};
		}
	});
};

export default http;
