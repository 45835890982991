import { isFunction, isArray } from '../util';
import BaseMixin from './BaseMixin';
import Resource from './Resource';
import Permission from './Permission';

export default class Scope extends BaseMixin() {

	constructor(props = {}){

		super(props);

		let {resources = []} = props;

		this.resources = resources = isFunction(resources) ? resources() : resources;

		for(let x in resources){

			let r = resources[x];

			if(!(r instanceof Resource)){

				r = resources[x] = new Resource(r);
			}

			r.scopes.push(this);
		}
	}

	hasResource(resource){

		return this.resources.some(r => r.key === resource || r.id === resource);
	}

	getResource(resource){

		return this.resources.find(r => r.key === resource || r.id === resource);
	}

	hasAction(resource, action, active){

		return (resource = this.getResource(resource)) ? resource.hasAction(action, active) : null;
	}

	getAction(resource, action, active){

		return (resource = this.getResource(resource)) ? resource.getAction(action, active) : null;
	}

	getResourcesActionsData(resources){

		let data = {};

		for(let r in resources){

			let resource = this.getResource(r);
			if(!resource) continue;
			data[r] = resource.getActionsData(resources[r]);
		}

		return data;
	}

	isAllowed(resource, action, match = Permission.MATCH_ALL){

		let r = this.getResource(resource);
		// if(!r) throw new Error(`[${this.constructor.name}]: The resource '${resource}' not exists in scope '${this.key||this.id}'.`);
		if(!r) return false;
		return action instanceof Array ? r.isAllowedMany(action, match) : r.isAllowed(action);
	}

	isAllowedMany(resources, match = Permission.MATCH_ALL){

		for(let r in resources){

			let result = false;
			let resource = this.getResource(r);
			//if(!resource) throw new Error(`[${caller}]: The resource '${r}' not exists in scope '${this.scope.key||this.scope.id}'.`);

			if(resource) {

				let rMap = resources[r];
				let rMatch = Permission.MATCH_ALL;
				let rActions = [];

				if (isArray(rMap)) {

					rActions = rMap;

				} else {

					rActions = rMap.actions || [];
					rMatch = rMap.match || rMatch;
				}

				result = resource.isAllowedMany(rActions, rMatch);
			}

			switch(match){

				case Permission.MATCH_ALL: {

					if(!result) return false;
					break;
				}

				case Permission.MATCH_ONE: {

					if(result) return true;
					break;
				}

				case Permission.MATCH_NONE: {

					if(result) return false;
					break;
				}
			}
		}

		return match !== Permission.MATCH_ONE;
	}
}
