export default function(store){

	store.subscribeAction({
		before: (action, state) => {
			// console.log('before', action, state);
		},
		after: (action, state) => {

			// console.log('after', action, state);
		}
	});
}