import session from '../modules/session';
import usuario from '../../modules/usuario/store';

const util = {

    mutations: {

        assign(_, { value, data }) {

            Object.assign(value, data);
        }
    }
};

export default {

    session,
    usuario
}
