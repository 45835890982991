export function normalizeText(value) {

	return value.toString().toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function uppercaseFirst(value) {

	return value && value.charAt(0).toUpperCase() + value.substr(1);
}

export function hasTextMatch(value, search) {

	return normalizeText(value).indexOf(normalizeText(search)) > -1;
}

export function find(data, search, keys = '*') {

	const fns = {

		normalize(value) {

			return value.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim()
		},
		check(item, keys) {

			for (let key in item) {

				if (keys && keys !== '*' && !keys[key]) continue;

				let value = item[key];

				if (isStringLike(value)) {

					if (search(fns.normalize(value))) return true;

				} else {

					if (keys && keys !== '*' && !keys[key]) continue;
					if (fns.check(value, keys[key] || '*')) return true;
				}
			}

			return false;
		}
	};

	if (isStringLike(search)) {

		const term = fns.normalize(search);

		search = (value) => {

			return value.indexOf(term) > -1;
		};
	}

	if (isArray(keys)) {

		let nKeys = {};

		for (let key of keys) {

			nKeys[key] = '*';
		}

		keys = nKeys;
	}

	let matches = [];

	for (let key in data) {

		let item = data[key];

		if (fns.check(item, keys)) {

			matches.push(item);
		}
	}

	return matches;
}

export function ensureArgs(data, { caller = null } = {}) {

	let bag = [];

	for (let prop in data) {

		if (data[prop] === undefined) {

			bag.push(prop);
		}
	}

	if (bag.length) throw new Error(`${caller ? `[${caller}]: ` : ``}Missing some required arg(s): ${bag.join(',')}.`);
}

export function isEmail(value) {

	return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value.toString());
}

export function isCns(value) {

	let cns = value.toString().trim();
	if (!cns || cns.length !== 15 || (cns[0] !== '7' && cns[0] !== '8'))
		return false;

	let sum = 0;

	for (let x = 0; x < 15; x++) {
		sum += cns[x] * (15 - x);
	}

	return sum % 11 === 0;
}

export function isCpf(value) {

	let cpf = (value || '').toString().trim().replace(/\D/g, '');
	if (!cpf || cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf))
		return false;

	let result = true;
	[9, 10].forEach(function (j) {
		let soma = 0, r;
		cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
			soma += parseInt(e) * ((j + 2) - (i + 1));
		});
		r = soma % 11;
		r = (r < 2) ? 0 : 11 - r;
		if (r != cpf.substring(j, j + 1)) result = false;
	});

	return result;
}

export function isCnpj(value) {

	let cnpj = value.toString().trim().replace(/\D/g, '');
	if (!cnpj || cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj))
		return false;

	let t = cnpj.length - 2,
		d = cnpj.substring(t),
		d1 = parseInt(d.charAt(0)),
		d2 = parseInt(d.charAt(1)),
		calc = x => {
			let n = cnpj.substring(0, x),
				y = x - 7,
				s = 0,
				r;

			for (let i = x; i >= 1; i--) {
				s += n.charAt(x - i) * y--;
				if (y < 2) y = 9
			}

			r = 11 - s % 11;
			return r > 9 ? 0 : r
		};

	return calc(t) === d1 && calc(t + 1) === d2;
}

export function formatCpf(value) {

	return value ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4") : undefined;
}

export function formatCnpj(value) {

	return value ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5") : undefined;
}

export function formatDecimal(value, digits = 2, locale = 'pt-BR', options = {}) {

	let opt = Object.assign({ minimumFractionDigits: digits, maximumFractionDigits: digits }, options);
	return isNumber(value) ? value.toLocaleString(locale, opt) : undefined;
}

export function upperCamelCase(data, isName = true) {

	if (!data) return "";

	if (isName) {

		return data.toString().trim().replace(/[^a-zà-ú'\s]/gi, ``).replace(/[a-z0-9_À-ú]\S*/gi, function (txt, offset) {

			if (txt.match(/^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/i)) {

				return txt;
			}

			txt = txt.toLowerCase();

			if (offset !== 0) {

				let artigos = ['o', 'a', 'à', 'á', 'e', 'com', 'os', 'as', 'um', 'uns', 'uma', 'umas', 'ao', 'aos', 'às', 'dum', 'das', 'da', 'de', 'di', 'do', 'du', 'duns', 'duma', 'dumas', 'em', 'no', 'nos', 'na', 'nas', 'num', 'nuns', 'numa', 'numas', 'por', 'per', 'pelo', 'pelos', 'pela', 'pelas'];
				if (txt.length === 1 || artigos.indexOf(txt) > -1) {

					if (data[offset - 1] !== '(') return txt;
				}
			}

			return txt.charAt(0).toUpperCase() + txt.substr(1);
		});
	}

	return data.replace(/\w\S*/g, function (txt) {

		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

export function isDefined(value) {

	return value !== undefined && value !== null;
}

export function isUndefined(value) {

	return value === undefined;
}

export function isBoolean(value) {

	return typeof value === 'boolean';
}

export function isString(value) {

	return typeof value === 'string';
}

export function isStringLike(value) {

	return typeof value === 'string' || !isNaN(value);
}

export function isNumber(value) {

	return typeof value === 'number' || (isDefined(value) && !isNaN(value));
}

export function isConstructor(value) {

	return value.prototype && value.prototype.constructor.name;
}

export function isEvent(value, type = null) {

	type = !isArray(type) && type ? [type] : type;
	if (!(value instanceof Event)) return false;
	return !type ? true : type.some((v) => isConstructor(v) ? value instanceof v : value.type === v);
}

export function isInteger(value, strict = false) {

	return strict ? Number.isInteger(value) : isNumber(value) && value % 1 === 0;
}

export function isFloat(value) {

	return isNumber(value) && value % 1 !== 0;
}

export function isObject(value) {

	return value === Object(value);
}

export function isArray(value) {

	return Array.isArray(value);
}

export function countProps(obj) {
	let count = 0;
	for (let k in obj) {
		if (obj.hasOwnProperty(k)) {
			count++;
		}
	}
	return count;
}

export function isObjectDeepEquals(v1, v2) {

	if (v1 === v2) return true;

	if (typeof (v1) !== typeof (v2)) {
		return false;
	}

	if (typeof (v1) === "function") {
		return v1.toString() === v2.toString();
	}

	if (v1 instanceof Object && v2 instanceof Object) {
		if (countProps(v1) !== countProps(v2)) {
			return false;
		}
		let r = true;
		for (let k in v1) {
			r = isObjectDeepEquals(v1[k], v2[k]);
			if (!r) {
				return false;
			}
		}
		return true;
	}

	return false;
}

export function isFunction(value) {

	return typeof value === 'function';
}

export function isPromise(value) {

	return !!value && isFunction(value.then);
}

export function isEmpty(value) {

	if (!isDefined(value)) {

		return true;

	} else if (isString(value)) {

		return value.trim() === '';

	} else if (isObject(value)) {

		return Object.keys(value).length === 0;

	} else if (isArray(value)) {

		return value.length === 0;
	}

	// else if (isFunction(value)) {
	//
	// 	return false;
	// }

	return false;
}

export function hasGetter(obj, prop) {

	const descriptor = Object.getOwnPropertyDescriptor(obj, prop);
	return !!(descriptor && descriptor['get']);
}

export function unobservable(target) {

	return Object.preventExtensions(target);

	// if(keys === '*') keys = target;
	// if(keys === '*'){
	//
	// 	target._isVue = true;
	// 	return target;
	// }
	//
	// let descriptor = {configurable: false};
	// let define = {};
	//
	// for(let k in keys){
	//
	// 	if(Object(keys[k]) === keys[k]){
	//
	// 		unobservable(target[k], {keys: '*'});
	// 	}
	//
	// 	define[k] = descriptor;
	// }
	//
	// Object.defineProperties(target, define);
	//
	// return target;
}

unobservable.keys = function (array) {

	for (let k in array) {

		Object.preventExtensions(array[k]);
	}

	return array;
};

export function base64UnicodeDecode(str) {
	return decodeURIComponent(atob(str).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
}

export function getDeepProperty(input, property, defaultValue = undefined) {

	if (!isObject(input)) throw new Error("Input needs to be a object.");

	let p = property.split('.');
	let x = p.length - 1;

	while (x >= 0) {

		if (p[x].substr(-1) === '\\' && x + 1 in p) {

			p[x + 1] = p[x].substr(0, p[x].length - 1) + '.' + p[x + 1];
			p.splice(x, 1);
		}

		x--;
	}

	for (let i of p) {

		if (!isObject(input)) {

			return defaultValue;
		}

		input = input[i];
	}

	return input;
}

export function isVNode(node) {

	return node !== null && typeof node === 'object' && hasOwnProperty.call(node, 'componentOptions');
}

export function isWords(value, options = { accents: true, space: true, case: 'insensitive' }) {

	let regexp = `A-Z`;
	let modifiers = options.case === 'insensitive' ? `i` : null;

	if (options.accents) regexp += `À-Ú`;
	if (options.space) regexp += `\\s`;

	return new RegExp(`^[${regexp}]+$`, modifiers).test(value);
}

export function isTelefone(value) {

	return /^\(\d{2}\)\s?\d?\d{4}-\d{4}$/.test(value);
}

export function isMobile() {

	return window.matchMedia("only screen and (max-width: 768px)").matches;
}

// export default {
//
// 	isDefined,
// 	isUndefined,
// 	isBoolean,
// 	isString,
// 	isNumber,
// 	isInteger,
// 	isFloat,
// 	isObject,
// 	isArray,
// 	isFunction,
// 	isPromise,
// 	isEmpty,
// 	ensureArgs,
// 	unobservable,
// 	base64UnicodeDecode,
// 	getDeepProperty,
// 	isVNode,
// 	isCns,
// 	isCpf,
// 	isCnpj,
// 	formatCpf,
// 	formatCnpj,
// 	upperCamelCase,
// 	isWords
// }
