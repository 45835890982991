import Vue from 'vue';
import Vuex from 'vuex';
import {isArray, isPromise, isString} from "@/resources/util";
import modules from './modules';
import hooks from './hooks';

Vue.use(Vuex);

const makeDefaultNamespaced = (modules) => {

	for(let k in modules){

		let module = modules[k];

		if(!('namespaced' in module)){

			module.namespaced = true;
		}

		if(module.modules){

			makeDefaultNamespaced(module.modules);
		}
	}
};

makeDefaultNamespaced(modules);

Vuex.Store.prototype.hasModule = function(path){

	// if(isString(path)) path = path.split('/');
	if(!isArray(path)) path = [path];

	let {_modules: {root: module}} = this;

	for(let x = 0; x < path.length; x++){

		if(!module._children[path[x]]) return false;
		module = module._children[path[x]];
	}

	return true;
};

Vuex.Store.prototype.registerLazyModule = function(name, module, options){

	options = {

		once: true,
		preserveState: false,
		... options
	};

	if(options.once && store.hasModule(name)) return store;

	let path = name;
	// if(isString(path)) path = path.split('/');

	if(isPromise(module)){

		return module.then(({default: module}) => {

			makeDefaultNamespaced([module]);
			store.registerModule(path, module);
		})

	} else {

		if(module.__esModule) module = module.default;
		makeDefaultNamespaced([module]);
		store.registerModule(path, module);
	}

	return store;
};

const store = new Vuex.Store({

	strict: true,
	plugins: [],
	modules
});

// console.log(store);

hooks(store);

export default store;