import { Modal } from "ant-design-vue";
import store from "@/store";

function getDeepVmIds(data, $vm) {

	data.push($vm._uid);

	for (let key in $vm.$children) {

		getDeepVmIds(data, $vm.$children[key]);
	}
}

export default function (router) {

	router.beforeEach(function (to, from, next) {

		if (to.path === "/login") return next({ name: 'acesso', hash: '#login', replace: true });

		// if(to.name === "notFound" && from.name === null && from.matched.length === 0) return next('/dashboard');

		const authenticated = store.getters["session/isAuthenticated"];

		if (to.name === 'convites') {

			next();

		} else if (!authenticated) {

			if (to.path !== '/acesso') {

				let route = {name: 'acesso'};
				if(to.name !== 'notFound') {

					route.query = {next: encodeURI(to.path)};
				}

				next(route);

			} else {

				next();
			}

		} else {

			const session = store.getters['session/user'];
			const defaultName = session.user.cliente ? 'vistorias' : 'minhas-vistorias';

			if (to.name === "main" || to.path === '/acesso'){

				return next({ name: defaultName });
			}

			let _uids = [];

			for (let match of from.matched) {

				for (let key in match.instances) {

					getDeepVmIds(_uids, match.instances[key]);
				}
			}

			if (store.getters['http/hasVmActiveRequests'](_uids)) {

				const confirm = Modal.confirm({

					title: `Existem ações pendentes nessa página. Deseja realmente sair?`,
					maskClosable: true,
					content: h => {

						let actions;

						if (store.getters['session/isUserDeveloper']) {

							actions = (

								<div>
									{JSON.stringify(store.getters['http/getActiveRequests'])}
									<a-progress status="active" />
								</div>
							);
						}

						return (

							<div>
								<span>
									Ao sair da página as ações pendentes poderão ser perdidas.
								</span>
								{/*{actions}*/}
							</div>
						)
					},
					okText: 'Sim',
					onOk() {

						store.commit('http/cancelVmActiveRequests', _uids);
						next();
						confirm.destroy();
					},
					cancelText: `Não`,
					onCancel() {

						confirm.destroy();
					}
				});

			} else {

				next();
			}
		}
	});
}
