import store from "@/store";
import { Permission, Action, Resource, Scope } from "@/resources/permission";
import Menu from "@/resources/Menu";
import modules from './modules';

const main = {

    path: '/',
    name: 'root',
    component: () => import('../components/app')
};

const routes = [

    {
        path: '/404',
        alias: '*',
        name: 'notFound',
        component: () => import('../components/not-found')
    },
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('../modules/login/components/form')
    // },
    {
        path: '/acesso',
        name: 'acesso',
        component: () => import('../modules/login/components/acesso')
    },
    {
        path: '/convites/:codigo',
        name: 'convites',
        component: () => import('../modules/convite')
    },
    main
];

export default function () {

    let { permissoes: permission, master, developer } = store.getters['session/user'];

    master = true;

    if (master) {

        permission = new Permission({

            master: true,
            developer
        });

    } else if (permission) {

        const scope = permission.scope;

        scope.resources = scope.resources.map(resource => {

            resource.actions = resource.actions.map(action => new Action(action));
            return new Resource(resource);
        });

        permission = new Permission({

            scope: permission.scope.key,
            master: permission.master,
            developer,
            scopes: [new Scope(scope)]
        });
    }

    const menu = new Menu(permission);

    main.children = [
        // {
        //     path: '/dashboard',
        //     name: 'dashboard',
        //     component: () => import('../modules/dashboard')
        // },
        {
            path: '/minhas-vistorias',
            name: 'minhas-vistorias',
            component: () => import('../modules/vistoria/components/list')
        },
        // {
        //     path: '/contratos',
        //     name: 'contratos',
        //     component: () => import('../modules/contrato/components/list')
        // },
        {
            path: '/vistorias',
            name: 'vistorias',
            component: () => import('../modules/vistoria/components/client-list')
        },
        {
            name: 'galeria',
            path: '/vistoria/:id([0-9]+)/galeria',
            component: () => import('../modules/vistoria/components/galeria')
        },
        {
            name: 'visualizacao',
            path: '/vistoria/:id([0-9]+)/galeria/:uuid',
            component: () => import('../modules/vistoria/components/visualizacao-foto')
        },
    ]

    if (permission) {

        main.children = [

            ...main.children,
            ...modules({ menu, permission })
        ];
    }

    return {

        routes,
        menu
    }
}
