import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

import {

    fasHome,
    fadMobileAndroid,
    farUser,
    farSignOutAlt,
    farInfoCircle,
    farSquare,
    fasListAlt,
    fasInfo,
    fasEye,
    fasHandshake,
    fasThumbsDown,
    fasFilePdf,
    fasDownload,
    fasUser,
    fasPlus,
    fasTrash,
    fasClock,
    farClock,
    farCommentAltDots,
    farQuestionCircle,
    fasEdit,
    fasEllipsisV,
    fasCircle,
    fasSignOutAlt,
    farCheckSquare,
    farLockAlt,
    farListUl,
    farCircle,
    farPlus,
    fasThumbsUp,
    fasQuestion,
    fadMobileAndroidAlt,
    falPaperclip,
    fasLockAlt,
    fasInfoCircle,
    fasSearch,
    fasFileSignature,
    farFileContract,
    farUserSecret,
    fasFileContract,
    fasPhoneRotary,
    fasPaperclip,
    fasArrowRight,
    fasArrowLeft,
    fasFileAlt,
    fasFileArchive,
    fasPhoneVolume,
    farClipboardList,
    fasClipboardList,
    fasFileInvoiceDollar,
    fasPrint,
    farMapPin,
    farUniversity,
    farEye,
    farIdBadge,
    farSign,
    farFileAlt,
    farHome,
    farPhoneRotary,
    fadClipboardList,
    fadFileContract,
    fadStarHalf,
    fadRulerTriangle,
    farMapMarkedAlt,
    farBuilding,
    farEnvelope,
    farPhoneAlt,
    farExternalLink,
    farCalendarDay,
    farMoneyBillWave,
    farBadgePercent,
    farCommentLines,
    farFileInvoiceDollar,
    farHandHoldingUsd,
    fasSignInAlt,
    fasColumns,
    fasKey,
    fasUserHeadset,
    fasQuestionCircle,
    fabTwitter,
    fabFacebookF,
    fabInstagram,
    fabWhatsapp,
    fasPhotoVideo, fasPlayCircle, fasFileEdit,
    fasDollarSign

} from '@/style/font-awesome/icons';

library.add(

    //FAS
    fasHome,
    fasListAlt,
    fasInfo,
    fasEye,
    fasHandshake,
    fasThumbsDown,
    fasThumbsUp,
    fasFilePdf,
    fasDownload,
    fasUser,
    fasPlus,
    fasTrash,
    fasEdit,
    fasQuestion,
    fasClock,
    fasEllipsisV,
    fasCircle,
    fasSignOutAlt,
    fasSearch,
    fasLockAlt,
    fasInfoCircle,
    fasFileSignature,
    fasFileContract,
    fasPhoneRotary,
    fasPaperclip,
    fasArrowRight,
    fasArrowLeft,
    fasFileAlt,
    fasFileArchive,
    fasPhoneVolume,
    fasClipboardList,
    fasFileInvoiceDollar,
    fasPrint,
    fasSignInAlt,
    fasColumns,
    fasKey,
    fasUserHeadset,
    fasQuestionCircle,
    fasPhotoVideo,
    fasPlayCircle,
    fasFileEdit,
    fasDollarSign,

    //FAR
    farUser,
    farLockAlt,
    farSignOutAlt,
    farInfoCircle,
    farSquare,
    farCheckSquare,
    farQuestionCircle,
    farListUl,
    farCircle,
    farClock,
    farCommentAltDots,
    farFileContract,
    farUserSecret,
    farPlus,
    farClipboardList,
    farMapPin,
    farUniversity,
    farEye,
    farIdBadge,
    farSign,
    farClock,
    farFileAlt,
    farHome,
    farPhoneRotary,
    farMapMarkedAlt,
    farBuilding,
    farEnvelope,
    farPhoneAlt,
    farExternalLink,
    farCalendarDay,
    farMoneyBillWave,
    farBadgePercent,
    farCommentLines,
    farFileInvoiceDollar,
    farHandHoldingUsd,

    //FAD
    fadMobileAndroid,
    fadMobileAndroidAlt,
    fadClipboardList,
    fadFileContract,
    fadStarHalf,
    fadRulerTriangle,

    //FAL
    falPaperclip,

    //FAB
    fabTwitter,
    fabFacebookF,
    fabInstagram,
    fabWhatsapp
);

export default function install(Vue) {

    Vue.component('g-icon', FontAwesomeIcon);
    Vue.component('g-icon-layers', FontAwesomeLayers);
}
