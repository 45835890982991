import { isString, isBoolean } from "@/resources/util";

function isValidMenu(menu){

	if(!menu.name) throw new Error(`Missing menu name into definition.`);
}

export default class Menu {

	constructor(permission, menus = []) {

		this.permission = permission;
		this.menus = menus;
	}

	getMenu(menu = null){

		if(!menu) return this.menus;

		let parts = isString(menu) ? menu.split('.') : menu;

		let current = this.menus;
		let part;

		l1: while((part = parts.shift())){

			for(let i of current){

				if(i.name === part){

					if(!parts.length) return i;
					current = i.children;
					continue l1;
				}
			}

			return null;
		}

		return null;
	}

	addChildren(menus){

		let m = [];

		for (let menu of menus) {

			let {route} = menu;
			let meta;

			if(route){

				if(route.meta && route.meta.auth){

					if(isBoolean(route.meta.auth.allowed)){

						if(!route.meta.auth.allowed) continue;

					} else {

						if(!this.permission.isAllowed(route.meta.auth.resource, route.meta.auth.action)) {

							continue;
						}
					}
				}

				meta = {

					name: menu.name || menu.route.name,
					label: menu.label || menu.route.meta.label,
					icon: menu.icon || menu.route.meta.icon
				};

			} else {

				meta = menu;
			}

			if(!menu.submenus){

				meta.path = menu.path || menu.route.path;

			} else {

				meta.submenus = this.addChildren(menu.submenus);
			}

			m.push(meta);
		}

		return m;
	}

	add(menus, to = null){

		if(to) {

			let menu = this.getMenu(to);
			if(!menu) throw new Error(`Cannot find menu level '${to}'.`);

			if(!menu.submenus) menu.submenus = [];
			menu.submenus = menu.submenus.concat(this.addChildren(menus));

		} else {

			this.menus = this.menus.concat(this.addChildren(menus));
		}

		return this;
	}

	set(menus, to = null){

		to = this.getMenu(to);
		if(!to) throw new Error(`Cannot find menu level '${to}'.`);
		to.children = menus;
	}

	remove(menu){}

	getMenus(){

		return clear(this.menus);
	}
}

const clear = function(menus){

	let data = [];

	for(let menu of menus) {

		if(menu.submenus){

			if(!menu.submenus.length) continue;
			clear(menu.submenus);
		}

		data.push(menu);
	}

	return data;
};

// let i = new Menu();

// console.log(i.getMenu('cidadao.saude'));

// i.add({name: "cidadao-cadastro", label: "Cadastro de cidadão"}, "cidadao");
// i.add({name: "cidadao-cadastro222", label: "Cadastro de cidadão22"}, "cidadao.cidadao-cadastro");
