import $http from "../../http";
import router from "../../router";

const state = {

    jwt: localStorage.getItem("jwt"),
    usuario: JSON.parse(localStorage.getItem("usuario")) || {}
};

const permissoes = state.usuario.permissoes;
delete state.usuario.permissoes;

if(state.jwt){

    $http.setDefaultAuthorization(state.jwt);
    // state.user = JSON.parse(base64UnicodeDecode(state.jwt.split('.')[1]));
}

if(state.usuario && state.usuario.timezone) {

    $http.setDefaultTimezone(state.usuario.timezone);
}

export default {

    namespaced: true,
    state,
    getters: {

        isUserDeveloper(state){

            return !!state.usuario.developer;
        },
        isLoading(state){

            return state.loading.length > 0;
        },
        /**
         * @return {boolean}
         */
        isAuthenticated(state){

            return !!state.jwt;
        },
        user(state){

            return {...state.usuario, permissoes};
        },
        jwt(state){

            return state.jwt;
        },
        all(state){

            return state;
        }
    },
    mutations: {

        setUsuario(state, payload){

            if(payload.timezone) {
                $http.setDefaultTimezone(payload.timezone);
            }
            localStorage.setItem('usuario', JSON.stringify(payload));
            state.usuario = payload;
        },
        setJwt(state, payload){

            if(!payload) {

                localStorage.removeItem("jwt");

            } else {

                localStorage.setItem("jwt", payload);
                $http.setDefaultAuthorization(payload);
            }

            state.jwt = payload;
        }
    },
    actions: {

        login({commit, state}, {payload}){

            return $http.post("/painel/sessions.login", payload).then(({data}) => {

                commit("setUsuario", data.payload);
                commit("setJwt", data.token);

                return data;
            });
        },
        logout({commit}){

            commit("setUsuario", {});
            commit("setJwt", null);

            return $http.post("/painel/sessions.logout").finally(() => {

                router.push("/acesso");
            });
        },
        changeUser({commit}, {payload}) {

            commit("setUsuario", payload);
        }
    }
}
