import http from "@/http";

export default {

    getters: {},
    mutations: {},
    actions: {

        get({commit, getters}, {payload: {id}}){

            return http.get('/painel/usuarios.obter', {params: {id}}).then(({data}) => {

                return data
            })
        },
        save({commit, getters}, {payload: usuario}){

            return http.post('/usuarios.salvar', {data: usuario}).then(({data}) => {

                return data

            }).catch(error => {

                return Promise.reject(error)
            })
        },
        saveProfile({commit, getters}, {payload: usuario}){

            return http.post('/usuarios.salvarPerfil', {data: usuario}).then(({data}) => {

                return data

            }).catch(error => {

                return Promise.reject(error)
            })
        }
    }
}
