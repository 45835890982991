import './env.config';
import Vue from 'vue'

import {
    Layout,
    Col,
    Row,
    Button,
    Dropdown,
    Menu,
    Pagination,
    Checkbox,
    DatePicker,
    FormModel,
    Input,
    InputNumber,
    Radio,
    Select,
    Steps,
    Switch,
    TimePicker,
    Upload,
    Avatar,
    Badge,
    Card,
    List,
    Table,
    Tag,
    Timeline,
    Tooltip,
    Alert,
    Modal,
    Popover,
    Progress,
    Result,
    Spin,
    ConfigProvider,
    message,
    Drawer,
    Empty,
    Comment,
    Tabs,
    Divider,
    Statistic,
    Rate
} from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.less';
import './style/index.less';
import wysiwyg from "./components/vue-wysiwyg/vueWysiwyg";
import "./components/vue-wysiwyg/vueWysiwyg.css";

import VueTheMask from 'vue-the-mask';

import './registerServiceWorker'
import router from './router'
import store from './store'
import Http from './http'

import components from './components';
components(Vue);

import directives from './directives';
directives(Vue);

Vue.use(Layout);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Pagination);
Vue.use(Checkbox);
Vue.use(DatePicker);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(Select);
Vue.use(Steps);
Vue.use(Switch);
Vue.use(TimePicker);
Vue.use(Upload);
Vue.use(Avatar);
Vue.use(Badge);
Vue.use(Card);
Vue.use(List);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Timeline);
Vue.use(Tooltip);
Vue.use(Alert);
Vue.use(Modal);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Result);
Vue.use(Spin);
Vue.use(ConfigProvider);
Vue.use(Drawer);
Vue.use(Empty);
Vue.use(Comment);
Vue.use(Tabs);
Vue.use(Divider);
Vue.use(Statistic);
Vue.use(Rate);

Vue.prototype.$message = message;
Vue.prototype.$error = Modal.error;
Vue.prototype.$success = Modal.success;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;

Vue.use(VueTheMask);
Vue.use(Http);

Vue.use(wysiwyg, { hideModules: { "image": true, "code": true }, height: "700px" });

Vue.config.productionTip = false

new Vue({

    router,
    store,
    render: h => <router-view></router-view>,
    beforeCreate() {

        if (this.$store) {

            const dispatch = this.$store.dispatch;

            this.$store.dispatch = function (type, payload, $vm) {

                return dispatch(type, { payload, $vm });
            }
        }
    }

}).$mount('#app');
