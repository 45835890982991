export default function ({ menu, permission }) {

    const routes = [

        {
            name: 'faturas-list',
            path: '/faturas',
            component: () => import('./fatura/components/list'),
            meta: {
                label: 'Listagem de Faturas',
                auth: { resource: 'financeiro', action: 'obter' }
            }
        },
        {
            name: 'faturas-view',
            path: '/faturas/:id([0-9]+)',
            component: () => import('./fatura/components/view'),
            meta: {
                label: 'Visua de nota fiscal',
                auth: { resource: 'financeiro', action: 'obter' }
            }
        },
        {
            name: 'cotacao-list',
            path: '/cotacao',
            component: () => import('./cotacao/components/list'),
            meta: {
                label: 'Cotação de Preços',
                // auth: { resource: 'cotacao', action: 'obter' }
            }
        },

    ];

    return routes;
}
