import { isObject, isDefined } from '../util';

const BaseMixin = (N = null) => {

	return class extends N {

		constructor(props = {}) {

			super(props);

			if(!isObject(props)) throw new Error(`Invalid object props passed to ${this.constructor.name} constructor.`);

			({

				id: this.id,
				key: this.key,
				name: this.name = null,
				description: this.description = '',
				active: this.active = true

			} = props);
		}

		_ensure(props = ['id', 'key']){

			let e = [];

			for(let p in props){

				if(!isDefined(this[props[p]])){

					e.push(props[p]);
				}
			}

			if(e.length) throw new Error(`[${this.constructor.name}] Missing some required props: ${e.join(',')}.`);
		}

		isActive(){

			return !!this.active;
		}
	}
};

export default BaseMixin;
