import { isBoolean } from "@/resources/util";
const unauthorized = () => import('../components/unauthorized');
import financeiro from "@/modules/financeiro/routes";

let modules = [

	financeiro,
];

export default function ({ menu, permission }) {

	const checkRoutesPermission = function (routes) {

		for (let key in routes) {

			let route = routes[key];

			if (route.meta && route.meta.auth) {

				if (isBoolean(route.meta.auth.allowed)) {

					if (!route.meta.auth.allowed) {

						route.component = unauthorized;
						continue;
					}
				}

				if (!permission.isAllowed(route.meta.auth.resource, route.meta.auth.action)) {

					route.component = unauthorized;
				}
			}

			if (route.children && route.children.length) checkRoutesPermission(route.children);
		}

		return routes;
	};

	return checkRoutesPermission(modules.reduce((routes, module) => {

		return [...routes, ...module({ menu, permission })];

	}, []));
}
