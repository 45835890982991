import { ensureArgs } from '../util';

export default class Permission {

	constructor(props){

		({
			master: this.master = false,
			developer: this.developer = false,
			scopes: this.scopes = [],

		} = props);

		if(props.scope) this.changeCurrentScope(props.scope);
	}

	static get MATCH_ALL(){

		return 1;
	}

	static get MATCH_ONE(){

		return 2;
	}

	static get MATCH_NONE(){

		return 3;
	}

	changeCurrentScope(scope){

		if(!this.hasScope(scope)){

			throw new Error(`The selected scope '${scope}' not exists.`);
		}

		this.scope = this.getScope(scope);
		return this;
	}

	hasScope(scope){

		return this.scopes.some(s => s.key === scope || s.id === scope);
	}

	getScope(scope){

		return this.scopes.find(s => s.key === scope || s.id === scope);
	}

	isDeveloper(){

		return this.developer;
	}

	isMaster(){

		return this.master;
	}

	hasAction(resource, action, active){

		const caller = this.constructor.name+'/hasAction';

		ensureArgs({

			resource,
			action

		}, {caller});

		return this.scope.hasAction(resource, action, active);
	}

	getAction(resource, action, active){

		const caller = this.constructor.name+'/getAction';

		ensureArgs({

			resource,
			action

		}, {caller});

		return this.scope.getAction(resource, action, active);
	}

	getResourcesActionsData(resources){

		return this.scope.getResourcesActionsData(resources);
	}

	isAllowed(resource, action, match = Permission.MATCH_ALL){

		const caller = this.constructor.name+'/isAllowed';

		ensureArgs({

			resource,
			action

		}, {caller});

		return this.isMaster() || this.scope.isAllowed(resource, action, match);
	}

	isAllowedMany(resources, match = Permission.MATCH_ALL){

		const caller = this.constructor.name+'/isAllowedMany';
		if(!resources) throw new Error(`[${caller}]: Missing required argument: 'resources'.`);
		if(this.isMaster()) return true;
		return this.scope.isAllowedMany(resources, match);
	}
}
